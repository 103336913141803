@font-face {
    font-family: "Calibre";
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/calibre/calibre-medium-italic.woff2") format("woff2"),
        url("./assets/fonts/calibre/calibre-medium-italic.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/calibre/calibre-semi-bold-italic.woff2") format("woff2"),
        url("./assets/fonts/calibre/calibre-semi-bold-italic.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/calibre/calibre-regular.woff2") format("woff2"),
        url("./assets/fonts/calibre/calibre-regular.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/calibre/calibre-medium.woff2") format("woff2"),
        url("./assets/fonts/calibre/calibre-medium.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/calibre/calibre-semi-bold.woff2") format("woff2"),
        url("./assets/fonts/calibre/calibre-semi-bold.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-weight: normal;
    font-style: italic;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/calibre/calibre-regular-italic.woff2") format("woff2"),
        url("./assets/fonts/calibre/calibre-regular-italic.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/calibre/calibre-light.woff2") format("woff2"),
        url("./assets/fonts/calibre/calibre-light.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/calibre/calibre-light-italic.woff2") format("woff2"),
        url("./assets/fonts/calibre/calibre-light-italic.woff") format("woff");
}

@font-face {
    font-family: "SF Mono";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/sfmono/sfmono-regular.woff2") format("woff2"),
        url("./assets/fonts/sfmono/sfmono-regular.woff") format("woff");
}

@font-face {
    font-family: "SF Mono";
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/sfmono/sfmono-semi-bold-italic.woff2") format("woff2"),
        url("./assets/fonts/sfmono/sfmono-semi-bold-italic.woff") format("woff");
}

@font-face {
    font-family: "SF Mono";
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/sfmono/sfmono-medium-italic.woff2") format("woff2"),
        url("./assets/fonts/sfmono/sfmono-medium-italic.woff") format("woff");
}

@font-face {
    font-family: "SF Mono";
    font-weight: normal;
    font-style: italic;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/sfmono/sfmono-regular-italic.woff2") format("woff2"),
        url("./assets/fonts/sfmono/sfmono-regular-italic.woff") format("woff");
}

@font-face {
    font-family: "SF Mono";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/sfmono/sfmono-medium.woff2") format("woff2"),
        url("./assets/fonts/sfmono/sfmono-medium.woff") format("woff");
}

@font-face {
    font-family: "SF Mono";
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
    src:
        url("./assets/fonts/sfmono/sfmono-semi-bold.woff2") format("woff2"),
        url("./assets/fonts/sfmono/sfmono-semi-bold.woff") format("woff");
}
